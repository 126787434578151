import './PremiumSupportItem.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { SVGIconOption } from '../../../models/icon';

import { SVGIcon } from '../../SVGIcon/SVGIcon';
interface Props {
  type: SVGIconOption;
  title: string;
  subTitle: string;
}

export const PremiumSupportItem = ({ subTitle, title, type }: Props) => {
  return (
    <div className="premium-support d-flex flex-column m-2 p-3 align-items-center">
      <div className="me-3 ms-2 mb-2">
        <SVGIcon shape="default" type={type} color={Color.DarkGrey}></SVGIcon>
      </div>

      <div>
        <h3 className="h4 mb-2">{title}</h3>
        <p className="text-center">{subTitle}</p>
      </div>
    </div>
  );
};
