import './Pill.scss';

import React from 'react';

import { Color } from '../../models/color';
import { PillSize } from '../../models/pill';
interface Props {
  bgColor?: Color;
  bColor?: Color;
  fColor?: Color;
  size: PillSize;
  text: string;
}

export const Pill = ({ bColor, bgColor, fColor, size, text }: Props) => {
  function getPillClass() {
    let classNames = '';

    if (bgColor) {
      classNames = `bg-${bgColor}`;

      if (bColor) {
        classNames += ` b-${bColor}`;
      } else {
        classNames += ` b-${bgColor}`;
      }
    } else if (bColor) {
      classNames = `b-${bColor}`;
    }

    if (fColor) classNames += ` f-${fColor}`;

    return classNames;
  }

  return <span className={`caption pill pill-${size} ${getPillClass()}`}>{text}</span>;
};
