import React from 'react';

export const RecentVideo = () => (
  <div className="py-5 px-4">
    <div className="rounded-4 bg-light-grey d-flex flex-column flex-lg-row">
      <div className="d-flex col-lg-6 align-items-center justify-content-center">
        <h2 className="my-5">What Others Are Saying</h2>
      </div>
      <iframe
        className="rounded-4 col-lg-6 col-md-12"
        id="ytplayer"
        height={300}
        src="https://www.youtube.com/embed/Ju9AOIJ8Ifo?autoplay=0&origin=https://perception.vision"></iframe>
    </div>
  </div>
);
