import React from 'react';

import { Color } from '../../models/color';
import { IconType } from '../../models/icon';

interface Props {
  type: IconType;
  color?: Color;
  margin?: string;
  logo?: boolean;
}

export const Icon = ({ color, logo, margin, type }: Props) => {
  function getImageFile() {
    let fileName = `${type}.svg`;

    if (color) fileName = `${color}-${fileName}`;

    return fileName;
  }

  function getImageAlt() {
    if (logo) {
      return `${type} logo`;
    }

    let alt = `A ${type} icon`;

    if (color) alt += ` in ${color}`;

    return alt;
  }

  return <img className={margin} src={`/images/icons/${getImageFile()}`} alt={getImageAlt()}></img>;
};
