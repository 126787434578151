export const TWITTER_URL = 'https://twitter.com/_PerceptionApp';
export const FACEBOOK_URL = 'https://www.facebook.com/perceptiondashcam';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/perception-app/';

export const MAC_DOWNLOAD_URL =
  'https://apps.apple.com/app/perception-sentry-viewer/id6448377884?utm_source=lander_generic&utm_medium=web';
export const DOWNLOAD_URL = 'https://perception-viewer.app.link/t28YV5iimLb';

export const PESLO_URL = 'https://peslostudios.com/?utm_source=perception&utm_medium=web';
export const PESLO_CONTACT_URL = 'https://peslostudios.com/contact?utm_source=perception&utm_medium=web';

export const PRESS_PACK_URL = 'https://drive.google.com/drive/folders/1YwI23UQw9GQKM3899Ok-mgex2b9L4Uo7';

export const TRIAL_LENGTH = 14;
export const CURRENT_VERSION = 'v2.3.0';
