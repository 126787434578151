import './NavigationIconItem.scss';

import React from 'react';

import { SVGIconOption } from '../../models/icon';

import { SVGIcon } from '../SVGIcon/SVGIcon';

type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  link: string;
  iconType: SVGIconOption;
};

export const NavigationIconItem = ({ icon, iconType, link, subtitle, title }: Props) => {
  return (
    <a
      href={`#${link}`}
      aria-label={`See ${title} feature`}
      className="dd-item d-flex flex-row m-1 p-2 align-items-center">
      <div className="me-3 mx-2">
        <SVGIcon type={iconType} icon={icon} shape="square" />
      </div>
      <div>
        <p className="a">{title}</p>
        {subtitle ? <span>{subtitle}</span> : ''}
      </div>
    </a>
  );
};
