import './AppButton.scss';

import React from 'react';

interface Props {
  img: string;
  alt: string;
  url: string;
  label: string;
}

export const AppButton = ({ alt, img, label, url }: Props) => (
  <a className="app-btn btn my-2 mx-3" href={url} target="_blank" rel="noreferrer" aria-label={label}>
    <img src={`images/app-download/${img}.svg`} alt={alt}></img>
  </a>
);
