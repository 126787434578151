import './Pricing.scss';

import React from 'react';

import { Color } from '../../models/color';
import { LogoType } from '../../models/logo';

import { TRIAL_LENGTH } from '../../utils/constants';

import { AppLogo } from '../AppLogo/AppLogo';

import { PricingCard } from './PricingCard/PricingCard';

const basicMessage = `${TRIAL_LENGTH} day free trial, no payment needed. Limited access during the trial; extra features require a Premium subscription.`;
const yearlyMessage = 'Pay upfront for a year of access and save 16% compared to paying monthly.';
const lifetimeMessage =
  'The average car lease spans three years, so we wanted to provide the best value for the duration you own the vehicle.';

export const Pricing = () => {
  return (
    <div className="row my-5 position-relative">
      <div id="pricing" className="anchor"></div>
      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        <AppLogo type={LogoType.PremiumGrey} />
      </div>
      <div className="d-flex align-items-stretch  pricing-list justify-content-center">
        <PricingCard period="Monthly" price={1.99} message={basicMessage} color={Color.Green} />
        <PricingCard
          period="Yearly"
          price={19.99}
          equivalentCostText={'(39p per week)'}
          message={yearlyMessage}
          label="Recommended"
          color={Color.Yellow}
          pillBgColor={Color.TransparentYellow}
          messageBg={Color.TransparentYellow}
        />
        <PricingCard
          period="Lifetime"
          price={59.99}
          equivalentCostText={'(39p per week, over 3 years)'}
          label="Best Value Over 3 Years"
          message={lifetimeMessage}
          color={Color.Blue}
          pillBgColor={Color.TransparentBlue}
        />
      </div>
    </div>
  );
};
