import './HomeBanner.scss';

import React from 'react';

import { LogoType } from '../../models/logo';

import { DOWNLOAD_URL, MAC_DOWNLOAD_URL } from '../../utils/constants';

import { AppLogo } from '../AppLogo/AppLogo';
import { AppButton } from '../DownloadButton/AppButton';

export const downloadInfo = [
  {
    img: 'mac-app-store',
    alt: "'Download on the Mac App Store' button",
    url: MAC_DOWNLOAD_URL,
    label: 'Download on the Mac App Store'
  },
  {
    img: 'app-store',
    alt: "'Download on the App Store' button",
    url: DOWNLOAD_URL,
    label: 'Download on the App Store'
  },
  {
    img: 'play-store',
    alt: "'Download on the Play Store' button",
    url: DOWNLOAD_URL,
    label: 'Download on the Google Play Store'
  }
];

export const HomeBanner = () => {
  const url = '/images/banner/hero-darken';
  const extension = 'webp';
  const backgroundSrcSet = `${url}-1x.${extension} 1x, ${url}-2x.${extension} 2x, ${url}-3x.${extension} 3x`;
  const backgroundSrc = `${url}.${extension}`;

  return (
    <div className="px-2 position-relative home-banner">
      <img
        aria-label="Hero Banner background image"
        className="hero-background-image"
        srcSet={backgroundSrcSet}
        src={backgroundSrc}
        alt="Person in an Tesla viewing a dash cam event through Perception on their phone"
      />

      <div className="py-5 d-flex flex-wrap home-banner-container">
        <div id="download" className="anchor"></div>
        <div className="home-content d-flex justify-content-center align-items-center flex-column">
          <div className="pb-2">
            <div className="mb-3 d-flex flex-column justify-content-center align-items-center">
              <h1 className="text-center unlock-header f-white">Unlock Your Full</h1>
              <div className="logo">
                <AppLogo type={LogoType.WhiteText} />
              </div>
            </div>
            <h2 className="h3 pb-3 f-lighter-grey">View, store, trim, manage and share your TeslaCam footage.</h2>
          </div>
          <div className="d-flex justify-content-center flex-wrap">
            {downloadInfo.map((downloadBtn, index) => {
              return (
                <AppButton
                  key={index}
                  img={downloadBtn.img}
                  alt={downloadBtn.alt}
                  url={downloadBtn.url}
                  label={downloadBtn.label}></AppButton>
              );
            })}
          </div>
          <small className="my-3 mb-1 text-center">
            Perception supports iOS & iPadOS 16 and later, macOS 13 and later, and Android 10 and later.
          </small>
        </div>
      </div>
    </div>
  );
};
