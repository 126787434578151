import './SVGIcon.scss';

import React from 'react';

import { Color } from '../../models/color';
import { SVGIconOption, SVGIconType } from '../../models/icon';

import { Icons } from '../Icons';

interface Props {
  icon?: React.ReactNode;
  type: SVGIconOption;
  shape: SVGIconType;
  strokeColor?: Color;
  fillColor?: Color;
  color?: Color;
  bgColor?: Color;
}

export const SVGIcon = ({ bgColor, color, icon, shape, type }: Props) => {
  function getIconClass() {
    let className = `icon-${type} icon-${shape}`;

    className += ` icon-${shape}`;
    if (color) className += ` icon-${color}`;
    if (bgColor) className += ` bg-${bgColor}`;

    return className;
  }

  /*function getIconAlt() {
    switch (type) {
      case SVGIconOption.Viewer:
        return 'A USB drive icon';
      case SVGIconOption.Markers:
        return 'A icon of a stack of files';
      case SVGIconOption.Offline:
        return 'A icon of a bookmark';
      case SVGIconOption.Export:
        return 'A icon of a arrow coming out of a rectangle';
      case SVGIconOption.MetaData:
        return 'A icon of a file';
      case SVGIconOption.Zoom:
        return 'A icon with four right angle arrange in a way when creates a square in the middle of the lines.';
      case SVGIconOption.Stars:
        return 'A icon of a couples of stars.';
      case SVGIconOption.Info:
        return 'A icon of a i encase in a circle.';
      case SVGIconOption.Email:
        return 'A icon of a envelope.';
      case SVGIconOption.Phone:
        return 'A icon of a phone right angle arrange in a way when creates a square in the middle of the lines.';
      case SVGIconOption.Tablet:
        return 'A icon of representing a tablet with the Perception P icon in the middle of the screen.';
      case SVGIconOption.Heart:
        return 'A icon of a heart.';
      default:
        return '';
    }
  }
*/
  function getIcon() {
    switch (type) {
      case SVGIconOption.Viewer:
        return <Icons.ViewerIcon />;
      case SVGIconOption.Export:
        return <Icons.ExportIcon />;
      case SVGIconOption.Markers:
        return <Icons.MarkersIcon />;
      case SVGIconOption.MetaData:
        return <Icons.MetaDataIcon />;
      case SVGIconOption.Offline:
        return <Icons.OfflineIcon />;
      case SVGIconOption.Zoom:
        return <Icons.ZoomIcon />;
      case SVGIconOption.Speed:
        return <Icons.SpeedIcon />;
      case SVGIconOption.Stars:
        return <Icons.StarsIcon />;
      case SVGIconOption.Wallet:
        return <Icons.WalletIcon />;
      case SVGIconOption.Info:
        return <Icons.InfoIcon />;
      case SVGIconOption.Email:
        return <Icons.EmailIcon />;
      case SVGIconOption.Phone:
        return <Icons.PhoneIcon />;
      case SVGIconOption.Tablet:
        return <Icons.TabletIcon />;
      case SVGIconOption.Heart:
        return <Icons.HeartIcon />;
      case SVGIconOption.Home:
        return <Icons.HomeIcon />;
    }
  }

  return (
    <div className={`${getIconClass()}`} /* aria-label={getIconAlt()} title={getIconAlt()}*/>{icon ?? getIcon()}</div>
  );
};
