import './Feature.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { convertFeatureToSVGIcon } from '../../../models/icon';
import { ImageExtension } from '../../../models/image';
import { PillSize } from '../../../models/pill';
import { Position } from '../../../models/position';

import { Feature as FeatureType, FeatureTypeKey } from '../../../constants/features';

import { Image } from '../../Image/Image';
import { Pill } from '../../Pill/Pill';
import { SVGIcon } from '../../SVGIcon/SVGIcon';

interface Props {
  feature: FeatureType;
  imageLocation: Position;
}

export const Feature = ({ feature, imageLocation }: Props) => {
  const getTextContainer = () => {
    return (
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center feature-text-container flex-column">
        <div className="d-flex feature-text">
          <div className="feature-icon d-flex align-items-center">
            <SVGIcon
              icon={<feature.icon stroke={feature.iconStroke} fill={feature.iconFill} />}
              type={convertFeatureToSVGIcon(feature.key)}
              shape="circle"
              bgColor={Color.DarkGrey}
            />
          </div>
          <div className="">
            <h2 className="text-start">{feature.longTitle}</h2>
            <h3 className="h4 text-start feature-subtitle">{feature.longDescription}</h3>
            {getFeatureChip()}
          </div>
        </div>
      </div>
    );
  };

  const getFeatureChip = () => {
    if (feature.key === FeatureTypeKey.Export) {
      return (
        <div className="py-3 d-flex pill-container">
          <div className="me-2">
            <Pill size={PillSize.Small} bColor={Color.LighterGrey} text=".MP4" />
          </div>
          <div className="ms-2">
            <Pill size={PillSize.Small} bColor={Color.LighterGrey} text=".ZIP" />
          </div>
        </div>
      );
    }

    return null;
  };

  const getImageContainer = () => {
    //if (!feature.imgAlt) return null;

    return (
      <div className="col-12 col-md-6">
        <div className="bg-light-grey feature-img">
          <Image alt="" img={`/features/screenshots/${feature.key}`} type={ImageExtension.WEBP} />
        </div>
      </div>
    );
  };

  return (
    <div className={`features-item features-${feature.key} features-${imageLocation} position-relative `}>
      <div id={feature.key} className="anchor"></div>
      <div className={`justify-content-around row ${imageLocation === Position.Start ? '' : 'flex-row-reverse'} `}>
        {getImageContainer()}
        {getTextContainer()}
      </div>
    </div>
  );
};
