import { FunctionComponent, SVGProps } from 'react';

import { Icons } from '../components/Icons';

import { Color, getColorVarFrom } from '../models/color';

export enum FeatureTypeKey {
  Viewer = 'viewer',
  Markers = 'markers',
  Store = 'store',
  Export = 'export',
  MetaData = 'meta-data',
  Zoom = 'zoom',
  Speed = 'speed',
  Filter = 'filter',
  Import = 'import',
  Map = 'map',
  Trim = 'trim',
  Search = 'search'
}

export interface Feature {
  key: FeatureTypeKey;
  longTitle: string;
  shortTitle: string;
  footerTitle: string;
  longDescription: string;
  shortDescription: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconStroke?: string;
  iconFill?: string;
  textListOnly?: boolean;
}

export const ViewFeature: Feature = {
  key: FeatureTypeKey.Viewer,
  longTitle: 'View Your Tesla Dashcam',
  shortTitle: 'TeslaCam Viewer App',
  footerTitle: 'View Your Tesla Dashcam',
  longDescription:
    'Plug in your USB drive, add your events, then view all angles simultaneously. You can also choose to add events from iCloud and on-device storage. NAS is unofficially supported.',
  shortDescription: 'View Dashcam and Sentry Mode events from the in-car USB',
  icon: Icons.ViewerIcon,
  iconStroke: getColorVarFrom(Color.Blue)
};

export const TrimFeature: Feature = {
  key: FeatureTypeKey.Trim,
  longTitle: 'Trim Your Events',
  shortTitle: 'Trim Events',
  footerTitle: 'Trim Your Events',
  longDescription: 'Edit your events to remove unneeded footage.',
  shortDescription: 'Edit your events to remove unneeded footage',
  icon: Icons.TrimIcon,
  iconStroke: getColorVarFrom(Color.Yellow)
};

export const StoreFeature: Feature = {
  key: FeatureTypeKey.Store,
  longTitle: 'Store Events In Perception',
  shortTitle: 'Store On Device',
  footerTitle: 'Store Events In Perception',
  longDescription:
    'View your Tesla dashcam and Sentry Mode events again, without needing the USB drive. Perception will keep them safe on your device.',
  shortDescription: 'Store Tesla events in Perception',
  icon: Icons.OfflineIcon,
  iconStroke: getColorVarFrom(Color.Red)
};

export const ExportFeature: Feature = {
  key: FeatureTypeKey.Export,
  longTitle: 'Export Your Footage',
  shortTitle: 'Export',
  footerTitle: 'Export Teslacam Footage',
  longDescription: 'Share your Tesla dashcam and Sentry Mode events.',
  shortDescription: 'Share your Teslacam events',
  icon: Icons.ExportIcon,
  iconStroke: getColorVarFrom(Color.Yellow)
};

export const ZoomFeature: Feature = {
  key: FeatureTypeKey.Zoom,
  longTitle: 'Get Up Close',
  shortTitle: 'Zoom',
  footerTitle: 'Get Up Close',
  longDescription: 'Zoom in to see the finer details, like license plates or faces.',
  shortDescription: 'See the finer details',
  icon: Icons.ZoomIcon,
  iconStroke: getColorVarFrom(Color.Blue)
};

export const MarkersFeature: Feature = {
  key: FeatureTypeKey.Markers,
  longTitle: 'Find The Incident Easily',
  shortTitle: 'Markers',
  footerTitle: 'Find The Incident Easily',
  longDescription: 'Intelligent markers help you find where the incident happened.',
  shortDescription: 'Find incidents easily',
  icon: Icons.MarkersIcon,
  iconStroke: getColorVarFrom(Color.Purple)
};

export const MetadataFeature: Feature = {
  key: FeatureTypeKey.MetaData,
  longTitle: 'Explore The Location, Check The Reason',
  shortTitle: 'Metadata',
  footerTitle: 'TeslaCam Metadata',
  longDescription: 'See the metadata for your Tesla dashcam and Sentry Mode events.',
  shortDescription: 'See event information',
  icon: Icons.MetaDataIcon,
  iconStroke: undefined,
  iconFill: getColorVarFrom(Color.Green)
};

export const PlaybackSpeedFeature: Feature = {
  key: FeatureTypeKey.Speed,
  longTitle: 'Speed Up, Slow Down',
  shortTitle: 'Playback Speed',
  footerTitle: 'Playback Speed',
  longDescription: 'From 0.25x to 5x, you decide the pace.',
  shortDescription: 'View Tesla events from 0.25x to 5x',
  icon: Icons.SpeedIcon,
  iconStroke: getColorVarFrom(Color.Purple)
};

export const FilterFeature: Feature = {
  key: FeatureTypeKey.Filter,
  longTitle: 'Find The Exact Event',
  shortTitle: 'Filters',
  footerTitle: 'Find The Exact Event',
  longDescription: 'Filter by Saved, Recent or Sentry Mode.',
  shortDescription: 'Filter events by type',
  icon: Icons.FilterIcon,
  iconStroke: getColorVarFrom(Color.Red)
};

export const ImportFeature: Feature = {
  key: FeatureTypeKey.Import,
  longTitle: 'Import at Rapid Speed',
  shortTitle: 'Rapid Import',
  footerTitle: 'Import at Rapid Speed',
  longDescription: 'Built for Efficiency: select a folder with multiple events and Perception will add them all.',
  shortDescription: 'Built for efficiency',
  icon: Icons.ImportIcon
};

export const MapFeature: Feature = {
  key: FeatureTypeKey.Map,
  longTitle: 'View by Location',
  shortTitle: 'View by Location',
  footerTitle: 'View by Location',
  longDescription: "Find where your TeslaCam events happened with Perception's Event World Map",
  shortDescription: 'Helping you find your events',
  icon: Icons.MapIcon,
  iconStroke: getColorVarFrom(Color.Green)
};

export const SearchFeature: Feature = {
  key: FeatureTypeKey.Search,
  longTitle: 'Search Events',
  shortTitle: 'Search Events',
  footerTitle: 'Search Events',
  longDescription: 'Find your Tesla dashcam and Sentry Mode events by location and reason.',
  shortDescription: 'Find your Tesla dashcam and Sentry Mode events by location and reason',
  icon: Icons.SearchIcon,
  iconStroke: getColorVarFrom(Color.Purple),
  textListOnly: true
};

export const AllFeatures: Feature[] = [
  ViewFeature,
  MapFeature,
  TrimFeature,
  SearchFeature,
  StoreFeature,
  ExportFeature,
  ImportFeature,
  PlaybackSpeedFeature,
  FilterFeature,
  MarkersFeature,
  MetadataFeature,
  ZoomFeature
];

const half = Math.ceil(AllFeatures.length / 2);

export const FeaturesLeftHalf: Feature[] = AllFeatures.slice(0, half);
export const FeaturesRightHalf: Feature[] = AllFeatures.slice(half);
