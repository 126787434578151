import React from 'react';

import { ActionBanner } from '../components/ActionBanner/ActionBanner';
import { FAQ } from '../components/FAQ/FAQ';
import { Features } from '../components/Features/Features';
import { HomeBanner } from '../components/HomeBanner/HomeBanner';
import { ImageBanner } from '../components/ImageBanner/ImageBanner';
import { PremiumBenefits } from '../components/PremiumBenefits/PremiumBenefits';
import { Pricing } from '../components/Pricing/Pricing';
import { RecentVideo } from '../components/RecentVideo';
import { Reviews } from '../components/Reviews';

import { ImageExtension } from '../models/image';

export const Home = () => (
  <div className="home-page">
    <HomeBanner />
    <Reviews />
    <div className="container-lg">
      <RecentVideo />
      <Features />
      <Pricing />
      <PremiumBenefits />
    </div>

    <ImageBanner
      img="banner/player"
      imgExtension={ImageExtension.WEBP}
      imgAlt="A user holding an iPhone watching a recent event in the Perception app"
      title="A Tesla Sentry Mode App Built For Tesla Owners, By Tesla Owners"
      subTitle="The best app for viewing your TeslaCam events."
    />

    <div className="container-lg">
      <FAQ />
      <ActionBanner
        id="request"
        customClass="pb-5"
        icon={true}
        title="Built Together"
        subTitle="Community feedback, ideas, and requests are all encouraged."
        btnText="Make a Request"
        btnLink="https://forms.gle/qjqrxd8LyW5ADeqC7"
      />
      <ActionBanner
        id="contact"
        customClass="pt-5"
        icon={false}
        title="Got an issue?"
        subTitle="Reach out and we'll help straightaway!"
        btnText="Contact Us"
        btnLink={'https://forms.gle/jgMHmNNBmYHkEM2f8'}
      />
    </div>
  </div>
);
