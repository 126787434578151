import './PremiumBenefitItem.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { convertFeatureToSVGIcon } from '../../../models/icon';

import { Feature } from '../../../constants/features';

import { SVGIcon } from '../../SVGIcon/SVGIcon';

interface Props {
  feature: Feature;
}

export const PremiumBenefitItem = ({ feature }: Props) => {
  const type = convertFeatureToSVGIcon(feature.key);

  return (
    <div className="premium-card d-flex flex-row my-2 p-3 align-items-center">
      <div className="me-3 mx-2">
        <SVGIcon icon={<feature.icon stroke={Color.DarkGrey} />} type={type} color={Color.DarkGrey} shape="default" />
      </div>

      <div>
        <h3 className="h4">{feature.shortTitle}</h3>
        {feature.shortDescription ? <span>{feature.shortDescription}</span> : ''}
      </div>
    </div>
  );
};
