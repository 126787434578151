import * as amplitude from '@amplitude/analytics-browser';

export type CookieSettingValue = 'enable' | 'disable' | null;

const COOKIE_KEY = 'pesloCookieSetting';

const initialize = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    // We're running on localhost - do not send analytics.
    console.error('IMPORTANT NOTE - ANALYTICS IS DISABLED IN DEV');

    return;
  }

  const trackingEnabled = getCookieState() === 'enable';

  amplitude.init('3499e0013ad53865607ca732c1274198', undefined, {
    defaultTracking: {
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: true
    },
    identityStorage: trackingEnabled ? 'cookie' : 'none',
    trackingOptions: {
      ipAddress: trackingEnabled
    }
  });
};

const getCookieState = (): CookieSettingValue => {
  return (localStorage.getItem(COOKIE_KEY) as CookieSettingValue) ?? null;
};

const setCookieState = (state: CookieSettingValue) => {
  localStorage.setItem(COOKIE_KEY, state ?? 'enable');
  initialize();
};

export const analytics = {
  initialize,
  getCookieState,
  setCookieState
};
