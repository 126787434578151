import './index.scss';

import React from 'react';

import { Icons } from '../../Icons';

export const Review = ({ date, text, title }: { date: string; title: string; text: string }) => {
  return (
    <div className="review bg-white rounded-4 p-4">
      <div className="bg-light-grey d-flex icon-container mb-3">
        <Icons.AppleIcon />
      </div>
      <small>{date}</small>
      <h3 className="py-2">{`"${title}"`}</h3>
      <p>{text}</p>
    </div>
  );
};
