import './ActionBanner.scss';

import React from 'react';

import { LogoType } from '../../models/logo';

import { AppLogo } from '../AppLogo/AppLogo';

interface Props {
  id: string;
  customClass: string;
  icon: boolean;
  title: string;
  subTitle: string;
  btnText: string;
  btnLink: string;
}

export const ActionBanner = ({ btnLink, btnText, customClass, icon, id, subTitle, title }: Props) => {
  return (
    <div className={`row action-banner my-5 position-relative ${customClass}`}>
      <div id={id} className="anchor"></div>
      {icon && (
        <div className="d-flex justify-content-center mb-4">
          <AppLogo type={LogoType.Grey} />
        </div>
      )}
      <h2>{title}</h2>
      <h3 className="h4 mt-2">{subTitle}</h3>
      <div className="d-flex justify-content-center mt-4">
        <a className="btn btn-secondary" href={btnLink} target="_blank" rel="noreferrer">
          <caption className="btn-text">{btnText}</caption>
        </a>
      </div>
    </div>
  );
};
