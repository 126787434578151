import './index.scss';

import React from 'react';

import { Icons } from '../Icons';

import { Review } from './Review';

export const Reviews = () => (
  <div className="py-5 d-flex flex-column bg-light-grey">
    <div className="container-lg flex-column d-flex">
      <div className="align-self-center pt-3 pb-3">
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
      </div>
      <h2 className="pb-2">1,000+ Satisfied Drivers</h2>
      <h4 className="pb-5">App Store Reviews</h4>
      <div className="reviews-container d-flex px-4 pb-5">
        <Review
          date="23 Jan 24"
          title="Handy tool"
          text="It’s a handy tool to export saved dashcam videos (e.g. to preserve them post incident)"
        />
        <div className="p-2" />
        <Review
          date="16 Jul 23"
          title="Just Works Fantastically"
          text="I rarely leave reviews, but when I come across something that 'just works fantastically', I have to write a compliment to the developer. Thank you 🙏"
        />
        <div className="p-2" />
        <Review
          date="26 Jul 23"
          title="Works perfectly and so easy to use"
          text="Does exactly what it's supposed to do. Works perfectly and so easy to use. Kudos to the developer!"
        />
        <div className="p-2" />
        <Review
          date="17 Jul 23"
          title="Wonderful app"
          text="Wonderful app - the design makes the app easy to use & view various camera angles with the use of the dashboard."
        />
      </div>
    </div>
  </div>
);
