import { FeatureTypeKey } from '../constants/features';

export enum IconType {
  Check = 'check',
  Star = 'star',
  Stars = 'stars',
  Linkedin = 'linkedin',
  Twitter = 'twitter',
  Facebook = 'facebook'
}

export enum SVGIconOption {
  Viewer = 'viewer',
  Markers = 'markers',
  Offline = 'offline',
  Export = 'export',
  MetaData = 'meta-data',
  Zoom = 'zoom',
  Search = 'search',
  Speed = 'speed',
  Stars = 'stars',
  Wallet = 'pricing',
  Info = 'faqs',
  Email = 'contact',
  Phone = 'download',
  Tablet = 'tablet',
  Heart = 'heart',
  Filter = 'filter',
  Import = 'import',
  Map = 'map',
  Trim = 'trim',
  Home = 'home'
}

export type SVGIconType = 'default' | 'circle' | 'square';

export const convertFeatureToSVGIcon = (feature: FeatureTypeKey) => {
  switch (feature) {
    case FeatureTypeKey.Viewer:
      return SVGIconOption.Viewer;
    case FeatureTypeKey.Markers:
      return SVGIconOption.Markers;
    case FeatureTypeKey.Store:
      return SVGIconOption.Offline;
    case FeatureTypeKey.Export:
      return SVGIconOption.Export;
    case FeatureTypeKey.MetaData:
      return SVGIconOption.MetaData;
    case FeatureTypeKey.Zoom:
      return SVGIconOption.Zoom;
    case FeatureTypeKey.Search:
      return SVGIconOption.Search;
    case FeatureTypeKey.Speed:
      return SVGIconOption.Speed;
    case FeatureTypeKey.Filter:
      return SVGIconOption.Filter;
    case FeatureTypeKey.Import:
      return SVGIconOption.Import;
    case FeatureTypeKey.Map:
      return SVGIconOption.Map;
    case FeatureTypeKey.Trim:
      return SVGIconOption.Trim;
  }
};
