import React from 'react';

import { LogoType } from '../../models/logo';
interface Props {
  type: LogoType;
}

function getLogoFile(type: LogoType): string {
  const logoFolder = '/images/logo/';

  switch (type) {
    case LogoType.White:
      return `${logoFolder}perception-white.svg`;
    case LogoType.Grey:
      return `${logoFolder}perception-grey.svg`;
    case LogoType.GreyText:
      return `${logoFolder}perception-grey-text.svg`;
    case LogoType.WhiteText:
      return `${logoFolder}perception-white-text.svg`;
    case LogoType.PremiumGrey:
      return `${logoFolder}perception-premium-grey.svg`;
    default:
      throw new Error('An unsupported type has been provided - likely it instead requires an animation file');
  }
}

function getLogoAlt(type: LogoType): string {
  switch (type) {
    case LogoType.White:
      return 'The Perception App logo';
    case LogoType.Grey:
      return 'The Perception App logo';
    case LogoType.GreyText:
      return 'The Perception word logo ';
    case LogoType.WhiteText:
      return 'The Perception word logo';
    case LogoType.PremiumGrey:
      return 'The Perception Premium logo';
    default:
      throw new Error('An unsupported type has been provided - likely it instead requires an animation file');
  }
}

export const AppLogo = ({ type }: Props) => (
  <a href="/" title="Perception Home Page" aria-label="Link to Perception Home Page">
    <img className="img-fluid" src={getLogoFile(type)} alt={getLogoAlt(type)} />
  </a>
);
