import React from 'react';
interface Props {
  question: string;
  answer: string;
}

export const FAQItem = ({ answer, question }: Props) => (
  <div className="my-3">
    <h3 className="text-start mb-2">{question}</h3>
    <p className="f-lighter-grey text-break">{answer}</p>
  </div>
);
