import colors from '../color.scss';

export enum Color {
  White = 'white',
  Paper = 'paper',
  TransparentWhite = 'transparent-white',
  LighterGrey = 'lighter-grey',
  LightGrey = 'light-grey',
  Grey = 'grey',
  DarkGrey = 'dark-grey',
  DarkYellow = 'dark-yellow',
  Yellow = 'yellow',
  TransparentYellow = 'transparent-yellow',
  DarkBlue = 'dark-blue',
  Blue = 'blue',
  TransparentBlue = 'transparent-blue',
  DarkPurple = 'dark-purple',
  Purple = 'purple',
  DarkGreen = 'dark-green',
  Green = 'green',
  TransparentGreen = 'transparent-green',
  DarkRed = 'dark-red',
  Red = 'red'
}

export const getColorVarFrom = (type: Color) => colors[type];
