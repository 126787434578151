import './NavigationMenu.scss';

import React from 'react';

import { SVGIconOption } from '../../../models/icon';

import { Icons } from '../../Icons';
import { NavigationIconItem } from '../../NavigationIconItem/NavigationIconItem';

export const NavigationMenu = () => {
  return (
    <div className="col-10 col-xl-4 align-items-center justify-content-end nav-mobile">
      <div className="dropdown d-flex">
        <button
          className="mobile-toggle dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-label="Toggle navigation menu window">
          <img className="mobile-menu-close" src="/images/icons/cross.svg" alt="Cross icon in white"></img>
          <img className="mobile-menu-toggle" src="/images/icons/menu.svg" alt="Icon with three horizontal lines"></img>
        </button>
        <div className="mobile-menu dropdown-menu dropdown-menu-end flex-column">
          <NavigationIconItem
            icon={<Icons.StarsIcon stroke="white" />}
            title="Features"
            link="features"
            iconType={SVGIconOption.Stars}
          />
          <NavigationIconItem
            link="pricing"
            icon={<Icons.WalletIcon stroke="white" />}
            title="Pricing"
            iconType={SVGIconOption.Wallet}
          />
          <NavigationIconItem
            icon={<Icons.InfoIcon stroke="white" />}
            iconType={SVGIconOption.Info}
            title="FAQs"
            link="faqs"
          />
          <NavigationIconItem
            icon={<Icons.EmailIcon stroke="white" />}
            iconType={SVGIconOption.Email}
            title="Contact"
            link="contact"
          />
          <NavigationIconItem
            icon={<Icons.PhoneIcon fill="white" />}
            iconType={SVGIconOption.Phone}
            title="Download"
            link="download"
          />
        </div>
      </div>
    </div>
  );
};
