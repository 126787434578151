import { ReactComponent as AppleIcon } from './assets/apple.svg';
import { ReactComponent as EmailIcon } from './assets/email.svg';
import { ReactComponent as ExportIcon } from './assets/export.svg';
import { ReactComponent as FilterIcon } from './assets/filter.svg';
import { ReactComponent as HeartIcon } from './assets/heart.svg';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as ImportIcon } from './assets/import.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import { ReactComponent as MapIcon } from './assets/map.svg';
import { ReactComponent as MarkersIcon } from './assets/markers.svg';
import { ReactComponent as MetaDataIcon } from './assets/meta-data.svg';
import { ReactComponent as OfflineIcon } from './assets/offline.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as SpeedIcon } from './assets/speed.svg';
import { ReactComponent as StarIcon } from './assets/star.svg';
import { ReactComponent as StarsIcon } from './assets/stars.svg';
import { ReactComponent as TabletIcon } from './assets/tablet.svg';
import { ReactComponent as TrimIcon } from './assets/trim.svg';
import { ReactComponent as ViewerIcon } from './assets/viewer.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';
import { ReactComponent as ZoomIcon } from './assets/zoom.svg';

export const Icons = {
  AppleIcon,
  EmailIcon,
  ExportIcon,
  FilterIcon,
  HeartIcon,
  HomeIcon,
  ImportIcon,
  InfoIcon,
  MapIcon,
  MarkersIcon,
  MetaDataIcon,
  OfflineIcon,
  PhoneIcon,
  SearchIcon,
  SpeedIcon,
  StarIcon,
  StarsIcon,
  TabletIcon,
  TrimIcon,
  ViewerIcon,
  WalletIcon,
  ZoomIcon
};
