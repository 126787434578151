import './PricingCard.scss';

import React from 'react';

import { Color } from '../../../models/color';
import { PillSize } from '../../../models/pill';

import { Alert } from '../../Alert/Alert';
import { Pill } from '../../Pill/Pill';

type Props = {
  period: string;
  price: number;
  salePrice?: number;
  equivalentCostText?: string;
  label?: string;
  message: string;
  color: Color;
  messageBg?: Color;
  pillBgColor?: Color;
  pillFontColor?: Color;
};

export const PricingCard = ({
  color,
  equivalentCostText,
  label,
  message,
  messageBg,
  period,
  pillBgColor,
  pillFontColor,
  price,
  salePrice
}: Props) => {
  const sale = salePrice !== undefined;

  const getPriceCardLabel = () => {
    let percentage = 0;

    if (!!salePrice) {
      percentage = Math.round(((price - salePrice) / price) * 100);
    }

    if (!label && salePrice) {
      label = `Save an extra ${percentage}% today`;
    }

    if (!label) {
      return null;
    }

    return (
      <div className="d-flex">
        <Pill size={PillSize.Large} bColor={color} bgColor={pillBgColor} fColor={pillFontColor} text={label} />
      </div>
    );
  };

  const getPriceContent = () => {
    if (sale) {
      return (
        <div>
          <div className="d-flex justify-content-center flex-wrap mt-auto mb-0 py-4">
            <p className="h4 f-lighter-grey full-price-sale">£{price}</p>
            <p className="h4 ms-2">£{salePrice}</p>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column align-items-center mt-auto mb-0 py-4">
        <p className="h4 ">£{price}</p>
      </div>
    );
  };

  const getPriceEquivalent = () => {
    if (equivalentCostText) {
      return <p className="h3 mt-2">{equivalentCostText}</p>;
    }

    return null;
  };

  const getPriceCardMessage = () => {
    return <Alert color={color} message={message} bgColor={messageBg}></Alert>;
  };

  const getPriceCardContent = () => {
    return (
      <div className={`card w-100 pt-5`}>
        {getPriceCardLabel()}
        <div className="p-3">
          <p className="text-center">
            PREMIUM <span className="pricing-card-period p">{period}</span>
          </p>
          {getPriceEquivalent()}
        </div>

        {getPriceContent()}
        {getPriceCardMessage()}
      </div>
    );
  };

  return <div className={`${sale ? 'sale-card' : ''} pricing-card b-paper`}>{getPriceCardContent()}</div>;
};
