// import { useEffect } from 'react';
import './ImageBanner.scss';

import React from 'react';

import { ImageExtension } from '../../models/image';

import { Image } from '../Image/Image';
interface Props {
  img: string;
  imgExtension: ImageExtension;
  imgAlt: string;
  title: string;
  subTitle: string;
}

export const ImageBanner = ({ img, imgAlt, imgExtension, subTitle, title }: Props) => {
  return (
    <div className="d-flex flex-column justify-content-center img-banner-container">
      <div className="mb-3">
        <h2 className="mb-2">{title}</h2>
        <h4>{subTitle}</h4>
      </div>

      <div id="imgBanner" className="img-banner">
        <Image img={img} type={imgExtension} alt={imgAlt}></Image>
      </div>
    </div>
  );
};
