import './Alert.scss';

import React from 'react';

import { Color } from '../../models/color';
import { SVGIconOption } from '../../models/icon';

import { SVGIcon } from '../SVGIcon/SVGIcon';

interface Props {
  color: Color;
  bgColor?: Color;
  message: string;
}

export const Alert = ({ bgColor, color, message }: Props) => {
  let backgroundColor = `bg-transparent-${color}`;

  if (bgColor) backgroundColor = `bg-${bgColor}`;

  return (
    <div className={`alert d-flex ${backgroundColor} mb-0 align-items-center`}>
      <div className="me-2 d-flex align-items-center">
        <SVGIcon type={SVGIconOption.Stars} color={color} shape="default" />
      </div>
      <span>{message}</span>
    </div>
  );
};
