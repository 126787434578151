import './Footer.scss';

import React from 'react';

import { IconType } from '../../models/icon';
import { LogoType } from '../../models/logo';

import { AllFeatures } from '../../constants/features';
import {
  DOWNLOAD_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  MAC_DOWNLOAD_URL,
  PESLO_CONTACT_URL,
  PESLO_URL,
  PRESS_PACK_URL,
  TWITTER_URL
} from '../../utils/constants';

import { AppLogo } from '../AppLogo/AppLogo';
import { Icon } from '../Icon/Icon';
import { NavigationItem } from '../NavigationItem/NavigationItem';

export const Footer = () => (
  <footer className="bg-dark-grey">
    <div className="container-xl pb-5 d-flex justify-content-center flex-wrap">
      <div className="d-flex flex-column px-3 footer-col justify-content-between info-container">
        <div className="d-flex info-image">
          <AppLogo type={LogoType.WhiteText} />
        </div>
        <div className="d-flex flex-column">
          <small className="my-2 trademarks">iOS, iPadOS, and MacOS are trademarks of Apple Inc.</small>
          <small className="my-2">Tesla is a trademark of Tesla Inc.</small>
          <small className="mt-4 f-light-grey info-copyright">{`Copyright ${new Date().getFullYear()} © Perception`}</small>
          <a className="mt-2 navigation-item" href={PESLO_URL} target="_blank" rel="noreferrer">
            <small className="f-light-grey info-copyright">{`A Peslo Production 🌱`}</small>
          </a>

          <div className="d-flex info-image social-links">
            <a
              href={LINKEDIN_URL}
              target="_blank"
              title="Link to Perception Linkedin Page"
              aria-label="Link to Perception Linkedin Page"
              rel="noreferrer">
              <Icon type={IconType.Linkedin} />
            </a>
            <div className="mx-2 border-end b-grey"></div>
            <a
              href={TWITTER_URL}
              target="_blank"
              title="Link to Perception Twitter Page"
              aria-label="Link to Perception Twitter Page"
              rel="noreferrer">
              <Icon type={IconType.Twitter} />
            </a>
            <div className="mx-2 border-end b-grey"></div>
            <a
              href={FACEBOOK_URL}
              target="_blank"
              title="Link to Perception Facebook Page"
              aria-label="Link to Perception Facebook Page"
              rel="noreferrer">
              <Icon type={IconType.Facebook} />
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h4 text-start">TeslaCam App</p>
        <NavigationItem title="iOS Download" link={DOWNLOAD_URL} external={true} />
        <NavigationItem title="macOS Download" link={MAC_DOWNLOAD_URL} external={true} />
        <NavigationItem title="Android Download" link={DOWNLOAD_URL} external={true} />
        <NavigationItem title="Pricing" link="#pricing" external={false} />
        <NavigationItem title="FAQs" link="#faqs" external={false} />
        <NavigationItem title="Press Pack" link={PRESS_PACK_URL} external />
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h4 text-start">Features</p>
        {AllFeatures.map(feature => (
          <NavigationItem key={feature.key} title={feature.footerTitle} link={`#${feature.key}`} external={false} />
        ))}
        <NavigationItem title="Make a Request" link="#request" external={false} />
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h4 text-start">Company</p>
        <NavigationItem title="Peslo Studios Ltd" link={PESLO_URL} external={true} />
        <NavigationItem title="Contact" link={PESLO_CONTACT_URL} external={true} />
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h4 text-start">Legal</p>
        <NavigationItem title="Cookie Policy" link="/cookies" external={false} />
        <NavigationItem title="Privacy Policy" link="/privacy" external={false} />
        <NavigationItem title="Terms of Service" link="/terms-of-service" external={false} />
      </div>
    </div>
  </footer>
);
