import React from 'react';

import { Position } from '../../models/position';

import { AllFeatures } from '../../constants/features';
import { CURRENT_VERSION } from '../../utils/constants';

import { Feature } from './Feature/Feature';

export const Features = () => (
  <div className="row position-relative pt-5">
    <div id="features" className="anchor"></div>
    <h2 className="pb-2">App Features</h2>
    <h4>{CURRENT_VERSION}</h4>
    {AllFeatures.filter(item => !item.textListOnly).map((item, index) => (
      <Feature key={item.key} feature={item} imageLocation={(index + 1) % 2 === 1 ? Position.End : Position.Start} />
    ))}
  </div>
);
